exports.components = {
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-pages-404-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/pages/404.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-pages-404-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-article-article-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/Article/Article.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-article-article-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-article-list-article-list-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/ArticleList/ArticleList.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-article-list-article-list-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-collection-collection-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/Collection/Collection.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-collection-collection-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-contributor-contributor-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/Contributor/Contributor.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-contributor-contributor-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-contributor-list-contributor-list-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/ContributorList/ContributorList.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-contributor-list-contributor-list-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-contributor-main-contributor-main-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/ContributorMain/ContributorMain.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-contributor-main-contributor-main-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-edition-edition-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/Edition/Edition.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-edition-edition-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-event-list-event-list-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/EventList/EventList.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-event-list-event-list-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-general-general-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/General/General.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-general-general-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-general-wide-general-wide-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/GeneralWide/GeneralWide.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-general-wide-general-wide-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-genre-genre-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/Genre/Genre.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-genre-genre-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-genre-imprint-genre-imprint-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/GenreImprint/GenreImprint.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-genre-imprint-genre-imprint-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-home-home-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/Home/Home.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-home-home-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-imprint-collection-imprint-collection-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/ImprintCollection/ImprintCollection.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-imprint-collection-imprint-collection-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-imprint-imprint-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/Imprint/Imprint.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-imprint-imprint-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-preview-general-js": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/Preview/general.js" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-preview-general-js" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-preview-general-wide-js": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/Preview/general-wide.js" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-preview-general-wide-js" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-preview-news-single-js": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/Preview/news-single.js" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-preview-news-single-js" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-publishing-this-week-publishing-this-week-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/PublishingThisWeek/PublishingThisWeek.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-publishing-this-week-publishing-this-week-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-resources-resources-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/Resources/Resources.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-resources-resources-tsx" */),
  "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-search-search-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/templates/Search/Search.tsx" /* webpackChunkName: "component---plugins-panmacmillan-gatsby-theme-panmacmillan-src-templates-search-search-tsx" */),
  "component---src-pages-sitemonitor-js": () => import("./../../../src/pages/sitemonitor.js" /* webpackChunkName: "component---src-pages-sitemonitor-js" */),
  "slice---plugins-panmacmillan-gatsby-theme-panmacmillan-src-components-footer-footer-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/components/Footer/Footer.tsx" /* webpackChunkName: "slice---plugins-panmacmillan-gatsby-theme-panmacmillan-src-components-footer-footer-tsx" */),
  "slice---plugins-panmacmillan-gatsby-theme-panmacmillan-src-components-header-header-tsx": () => import("./../../../plugins/@panmacmillan/gatsby-theme-panmacmillan/src/components/Header/Header.tsx" /* webpackChunkName: "slice---plugins-panmacmillan-gatsby-theme-panmacmillan-src-components-header-header-tsx" */)
}

